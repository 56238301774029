import type { NavigateFunction } from "@remix-run/react";
import { useNavigate } from "@remix-run/react";
import type { FC } from "react";
import { useCallback } from "react";
import { createContext, useContext } from "react";
import type { NavigateOptions } from "react-router";
import { useLocation } from "react-router";

import zh from "../i18n/zh.json";
import en from "../i18n/en.json";
import cn from "../i18n/cn.json";

const i18nJSON: any = { zh, en, cn };

export enum AvailableLocaleType {
  "zh" = "zh",
  "en" = "en",
  "cn" = "cn",
  "zh-hk" = "zh-hk",
}

export enum AvailableRegionType {
  "hk" = "hk",
  "mo" = "mo",
}

export const fallbackLocale = AvailableLocaleType.zh;
export const fallbackRegion = AvailableRegionType.hk;

export const isLocaleValid = (locale: string | AvailableLocaleType) => {
  return Object.keys(AvailableLocaleType).includes(locale as string);
};

export const isRegionValid = (region: string | AvailableRegionType) => {
  return Object.keys(AvailableRegionType).includes(region as string);
};

export const useLocale = (): AvailableLocaleType => {
  const { locale } = useContext(i18nContext);

  if (!locale || !isLocaleValid(locale!)) {
    throw new Error("[Remix][i18n] Unknown Locale!");
  } else {
    return locale! as unknown as AvailableLocaleType;
  }
};

export const useRegion = (): AvailableRegionType => {
  const { region } = useContext(i18nContext);

  if (!region || !isRegionValid(region!)) {
    throw new Error("[Remix][i18n] Unknown region!");
  } else {
    return region! as unknown as AvailableRegionType;
  }
};

type I18nContextType = {
  i18n: any;
  t: (key: string) => string;
  locale: AvailableLocaleType;
  region: AvailableRegionType;
};

const i18nContext = createContext<Partial<I18nContextType>>({});

export const useI18nContextInit = ({
  locale,
  region,
}: {
  locale: AvailableLocaleType;
  region: AvailableRegionType;
}): I18nContextType => {
  const t = useCallback(
    (key: string, _locale?: AvailableLocaleType) => {
      return (
        key
          .split(".")
          .reduce((o, i) => o?.[i] ?? "", i18nJSON?.[_locale ?? locale]) ??
        key ??
        ""
      );
    },
    [locale]
  );

  return {
    t,
    locale: locale as AvailableLocaleType,
    region: region as AvailableRegionType,
    i18n: i18nJSON,
  };
};

export const I18nProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const locale = getLocaleFromPath(useLocation().pathname);
  const region = getRegionFromPath(useLocation().pathname);
  const value = useI18nContextInit({ locale, region });
  return <i18nContext.Provider value={value}>{children}</i18nContext.Provider>;
};

export const useTranslation = () => {
  const { t } = useContext(i18nContext);
  return t!;
};

export const useLocaleNavigate = (): NavigateFunction => {
  const locale = useLocale();
  const region = useRegion();
  const navigate = useNavigate();
  return (to: any, options?: NavigateOptions) => {
    navigate(`/${region}/${locale}${to}`, options);
  };
};

export const useSetLocale = (): NavigateFunction => {
  const location = useLocation();
  const navigate = useNavigate();
  return useCallback(
    (newLocale: any, options?: NavigateOptions) => {
      const newPath = changePathLocale(location.pathname, newLocale);
      navigate(newPath, options);
    },
    [location.pathname, navigate]
  );
};

export const getRegionFromPath = (path: string): AvailableRegionType => {
  const region = path.split("/")[1];
  return isRegionValid(region)
    ? (region as AvailableRegionType)
    : fallbackRegion;
};

export const getLocaleFromPath = (path: string): AvailableLocaleType => {
  const locale = path.split("/")[2];
  return isLocaleValid(locale)
    ? (locale as AvailableLocaleType)
    : fallbackLocale;
};

export const getPathWithoutLocale = (path: string): string => {
  const locale = getLocaleFromPath(path);
  const region = getRegionFromPath(path);
  return path.replace(`/${region}/${locale}`, "");
};

export const changePathLocale = (
  path: string,
  newLocale: AvailableLocaleType
) => {
  const locale = getLocaleFromPath(path);
  const region = getRegionFromPath(path);

  return path.replace(`/${region}/${locale}`, `/${region}/${newLocale}`);
};

export const changePathRegion = (
  path: string,
  newRegion: AvailableRegionType
) => {
  const locale = getLocaleFromPath(path);
  const region = getRegionFromPath(path);
  return path.replace(`/${region}/${locale}`, `/${newRegion}/${locale}`);
};

export const getLocalePath = (path: string, locale: AvailableLocaleType) => {
  return `/${locale}${path}`;
};

export const getRegionLocaleIsValid = (path: string) => {
  const locale = getLocaleFromPath(path);
  const region = getRegionFromPath(path);
  return isRegionValid(region) && isLocaleValid(locale);
};
